import "i18n";
/**
 * unique id for each tab
 */
export let tabId: string;

window.addEventListener("beforeunload", () => {
  window.sessionStorage.setItem("tabId", tabId);

  return null;
});

window.addEventListener("load", () => {
  const prevTabId = window.sessionStorage.getItem("tabId");

  if (prevTabId) {
    tabId = prevTabId;
    window.sessionStorage.removeItem("tabId");
  } else {
    tabId = Math.random().toString(36).substring(2);
  }

  return null;
});
