import type { UserContext } from "components/hoc/user/UserProvider";
import type { SavedUser } from "model/User";
import { USER_STORAGE_KEY } from "util/const";
import { createCtx } from "util/context";
export const [useUserCtx, Provider] = createCtx<UserContext>();

export const getRawUser = (): SavedUser | undefined => {
  const user = window.userStorage.getItem(USER_STORAGE_KEY);

  if (user) {
    try {
      const parsed = JSON.parse(user);

      if (
        typeof parsed === "object" &&
        "id" in parsed &&
        "username" in parsed &&
        "email" in parsed &&
        "companyId" in parsed &&
        "companySlug" in parsed
      ) {
        return parsed;
      }
    } catch {
      //
    }
  }

  return undefined;
};
