import type { ThemeState } from "@panel2/systail-ui/util";
export const colorPresets = {
  purple: "#6750a4",
  gray: "#0a335b",
  blue: "#4070de", // rgb 64, 112, 222
};
export const defaultTheme: ThemeState = {
  mode: "light",
  // mode: window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light",
  leading: colorPresets.purple,
  contrast: 0.2,
};

export const getDisabledLoginOptions = (slug: string): ("azure" | "ldap")[] => {
  const dictStr = import.meta.env.VITE_SLUG_DISABLED_LOGIN_TYPES_DICT || "{}";
  const disabledLoginOptionsDict: Record<string, ("azure" | "ldap")[]> = {};

  try {
    let dict = JSON.parse(dictStr);

    if (
      typeof dict !== "object" ||
      dict === null ||
      Object.values(dict).some(v => typeof v !== "string")
    ) {
      dict = {};
    }

    for (const key in dict) {
      if (Object.prototype.hasOwnProperty.call(dict, key)) {
        const value = dict[key];
        disabledLoginOptionsDict[key] = value
          .split(",")
          .filter((v: string): v is "azure" | "ldap" => v === "azure" || v === "ldap");
      }
    }
  } catch {}

  return disabledLoginOptionsDict[import.meta.env.VITE_FORCE_SLUG || slug] || [];
};

export const allLoginOptions: ("internal" | "azure" | "ldap")[] = ["internal", "azure", "ldap"];

export const getAvailableOtherLoginOptions = (slug: string): ("azure" | "ldap")[] => {
  return allLoginOptions.filter(
    (v): v is "azure" | "ldap" => v !== "internal" && !getDisabledLoginOptions(slug).includes(v),
  );
};

export const popupCenter = (url: string, title: string, w: number, h: number) => {
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width;

  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;

  const newWindow = window.open(
    url,
    title,
    `
    scrollbars=yes,
    width=${w / systemZoom}, 
    height=${h / systemZoom}, 
    top=${top}, 
    left=${left}
    `,
  );

  return newWindow;
};
