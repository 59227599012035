import { FULL_USERNAME_REGEX, USERNAME_REGEX } from "util/const";
export const checkUsername = (e?: string) =>
  e === undefined
    ? ""
    : !e
      ? t`login.username_required|Username is Required`
      : !USERNAME_REGEX.test(e)
        ? t`login.username_incorrect|Incorrect Username`
        : "";
export const checkFullUsername = (e?: string) =>
  e === undefined
    ? ""
    : !e
      ? t`login.username_required|Username is Required`
      : !FULL_USERNAME_REGEX.test(e)
        ? t`login.username_incorrect|Incorrect Username`
        : "";
export const checkCompany = (e?: string) =>
  e === undefined ? "" : !e ? t`login.company_required|Company is Required` : "";

export const checkPass = (e?: string) =>
  e === undefined ? "" : !e ? t`login.pass_required|Password is Required` : "";

export const checkPassLength = (length: number, e?: string) =>
  e === undefined
    ? ""
    : e && e.length < length
      ? t`login.pass_required_length_check|Your password must be at least {length} characters ${{
          length,
        }}`
      : "";

export const checkDomain = (e?: string) =>
  e === undefined ? "" : !e ? t`login.domain_required|Domain is Required` : "";
export const isRequired = <T>(n: T | undefined | null, what: string) =>
  (n ?? true) === true
    ? t`labels.sth_is_required|{what} is required ${{
        what,
      }}`
    : undefined;
export const notEmpty = (s: string | undefined, what: string) =>
  !s?.trim()
    ? t`labels.what_cannot_be_empty|{what} cannot be empty ${{
        what,
      }}`
    : undefined;

export const checkPasswordsMatch = (password1?: string, password2?: string) =>
  password1 === undefined || password2 === undefined
    ? ""
    : !password1 || !password2
      ? t`login.pass_required|Password is Required`
      : password1 !== password2
        ? t`login.passwords_not_match|Passwords do not match`
        : "";
