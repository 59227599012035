import { useEffect, useRef } from "react";

type OnChange = (() => undefined) | (() => void);

const useChangeObserver = <T>(
  onChange: OnChange,
  values: T[],
  reportChangeOnFirstValue = true,
): void => {
  const initialValuesRef = useRef<T[]>(reportChangeOnFirstValue ? [] : values);
  const last = useRef<T[]>(initialValuesRef.current);
  const onChangeRef = useRef<OnChange>(onChange);

  useEffect(() => {
    onChangeRef.current = onChange;
    initialValuesRef.current = reportChangeOnFirstValue ? [] : values;
  }, [onChange, reportChangeOnFirstValue, values]);

  useEffect(() => {
    if (last.current.length !== values.length) {
      last.current = values;
      const cleanup = onChangeRef.current();

      return cleanup;
    }

    for (let i = 0; i < values.length; ++i) {
      if (last.current[i] !== values[i]) {
        last.current = values;
        const cleanup = onChangeRef.current();

        return cleanup;
      }
    }
  }, [values]);

  useEffect(
    () => () => {
      last.current = initialValuesRef.current;
    },
    [],
  );
};

export default useChangeObserver;
