import { useMutation } from "@tanstack/react-query";
import { reset } from "api/auth/login";
import Done from "components/atoms/icons/Done";
import { useNotifierCtx } from "components/hoc/notifications/provider";
import ForgotFormControl from "components/molecules/forgot_form_control/ForgotFormControl";
import { useForgotCtx } from "components/organisms/forgot/provider";
import { useSlug } from "provider";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { notifyError } from "util/misc";

const ForgotForm = (): JSX.Element => {
  const notify = useNotifierCtx();
  const navigate = useNavigate();
  const slug = useSlug();

  const { formOk, email } = useForgotCtx();

  const mutation = useMutation({
    mutationFn: reset,
    onError: (error: Error) => {
      notifyError(notify, <span>{t`reset.failed|Reset Failed`}</span>, error);
    },
    onSuccess: () => {
      notify({
        title: t`labels.reset_send_successful|Reset send successful`,
        variant: "success",
        content: (
          <div className="flex">
            <Done size="32px" className="py-auto pr-2 text-green-500" />

            <span className="my-auto">
              <span>{t`labels.reset_send_successfully|Reset send successfully`}</span>
            </span>
          </div>
        ),
      });

      navigate(`/${slug}/forgot_info`);
    },
  });

  const onSubmit = useCallback(() => {
    if (formOk && email) {
      mutation.mutate({
        email,
      });
    }
  }, [formOk, email, mutation]);

  return (
    <div className="flex h-full w-full">
      <div className="delayed-grow flex max-w-[1000px] grow">
        <div className="delayed-grow m-auto flex flex-col lg:ml-auto">
          <div className="rounded-t border-x-4 border-t-4 border-surface-variant bg-surface px-5 pb-3 pt-5 drop-shadow-xl">
            <h2 className="my-4 select-none text-4xl text-on-surface">
              <strong>
                <span>{t`titles.forgot_password|Forgot password`}</span>
              </strong>
            </h2>
          </div>
          <div className="m-0 h-1" />
          <div className="rounded-b border-x-4 border-b-4 border-surface-variant bg-surface px-4 pb-3 pt-4 drop-shadow-xl">
            <div className="stale flex flex-col p-2">
              <small>
                {t`labels.enter_your_email_address_to_reset_password|Enter your email address to reset password`}
              </small>
            </div>
            <ForgotFormControl loading={mutation.isPending} onSubmit={onSubmit} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotForm;
