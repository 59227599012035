import { getProtectedApi } from "api/common";
import { MERCURE_HUB_URL } from "util/const";
export const mercurePush = ({
  body,
  token,
}: {
  body: URLSearchParams;
  token: string;
}) =>
  getProtectedApi()
    .post(MERCURE_HUB_URL, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "application/x-www-form-urlencoded",
      },
    })
    .then(res => res.data);
