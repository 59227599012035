const LoginLoader = (): JSX.Element => (
  <div className="flex h-full w-full">
    <div className="delayed-grow flex max-w-[1000px] grow">
      <div className="m-auto overflow-hidden rounded shadow lg:ml-auto">
        <div className="mb-2 bg-surface-container px-5 pb-3 pt-5 shadow">
          <div
            className="shine mb-3 ml-3 mr-auto mt-5 rounded bg-surface-container"
            style={{ height: 20, width: "30%" }}
          />
        </div>
        <div className="bg-surface-container px-5 pb-4 pt-5 shadow">
          <div
            className="shine mt-4 rounded bg-surface-container"
            style={{ height: 20, width: 250 }}
          />
          <div
            className="shine mt-3 rounded bg-surface-container"
            style={{ height: 20, width: 250 }}
          />
          <div
            className="w-25 shine mb-2 mt-4 rounded bg-surface-container"
            style={{ height: 20 }}
          />
          <div
            className="w-25 shine ml-auto mt-3 rounded bg-surface-container"
            style={{ height: 30 }}
          />
        </div>
      </div>
    </div>
  </div>
);

export default LoginLoader;
