import type { SavedUser, UserState } from "model/User";
import { type ReactNode, type SetStateAction, useCallback, useMemo, useState } from "react";
import { LAST_URL_KEY, USER_STORAGE_KEY } from "util/const";
import { JWTExpirationDate, clearSavedState } from "util/misc";

import { Provider } from "components/hoc/user/provider";

export interface UserContext {
  user?: SavedUser;
  availableUsers: UserState[];
  login: (us: UserState) => void;
  changeUser: (userId: string) => void;
  logout: () => void;
}

interface Props {
  children: ReactNode;
}

interface UserStorage {
  getItem: (key: string) => string;
  setItem: (
    key: string,
    value: string,
    options?: {
      expire?: Date;
    },
  ) => void;
  removeItem: (key: string) => void;
  length: number;
  clear: () => void;
  key: (index: number) => string | null;
}

declare global {
  interface Window {
    userStorage: UserStorage;
  }
}

const UserProvider = ({ children }: Props): JSX.Element => {
  const [users, _setUsers] = useState<Record<string, UserState>>(() => {
    const savedUsers = window.userStorage.getItem(`${USER_STORAGE_KEY}-list`);

    if (savedUsers) {
      return JSON.parse(savedUsers);
    }

    return {};
  });

  const [activeUser, _setActiveUser] = useState<SavedUser | undefined>(() => {
    const savedUser = window.userStorage.getItem(USER_STORAGE_KEY);

    if (savedUser) {
      return JSON.parse(savedUser);
    }

    return undefined;
  });

  const setUsers = useCallback((action: SetStateAction<Record<string, UserState>>) => {
    _setUsers(prev => {
      const users = typeof action === "function" ? action(prev) : action;

      window.userStorage.setItem(`${USER_STORAGE_KEY}-list`, JSON.stringify(users), {
        expire: undefined, // expire with end of session
      });

      return users;
    });
  }, []);

  const setActiveUser = useCallback((action: SetStateAction<SavedUser | undefined>) => {
    _setActiveUser(prev => {
      const user = typeof action === "function" ? action(prev) : action;

      if (user) {
        let expire: Date | undefined = undefined;

        if (user.keepLogged) {
          // set expire to jwt expiration date
          const jwtExpiresIn = JWTExpirationDate(user.tokens[0]);
          expire = new Date(jwtExpiresIn);
        }

        window.userStorage.setItem(USER_STORAGE_KEY, JSON.stringify(user), {
          expire,
        });
      } else {
        window.userStorage.removeItem(USER_STORAGE_KEY);
      }

      return user;
    });
  }, []);

  const login = useCallback(
    ({ tokens, ...user }: UserState) => {
      const lastUrl = sessionStorage.getItem(LAST_URL_KEY);
      clearSavedState();

      setActiveUser({
        ...user,
        tokens,
      });

      setUsers(old => ({
        ...old,
        [user.id]: { tokens, ...user },
      }));

      if (lastUrl) {
        sessionStorage.setItem(LAST_URL_KEY, lastUrl);
      }

      document.body.classList.add("logged");
    },
    [setActiveUser, setUsers],
  );

  const logout = useCallback(() => {
    const lastUrl = sessionStorage.getItem(LAST_URL_KEY);
    setActiveUser(undefined);
    setUsers({});
    clearSavedState();
    sessionStorage.setItem(LAST_URL_KEY, lastUrl ?? "");
    document.body.classList.remove("logged");
  }, [setActiveUser, setUsers]);

  const changeUser = useCallback(
    (userId: string) => {
      if (users[userId]) {
        setActiveUser(users[userId]);
        window.location.reload();
      } else {
        logout();
      }
    },
    [logout, setActiveUser, users],
  );

  return (
    <Provider
      value={useMemo(
        () => ({
          user: activeUser,
          availableUsers: Object.values(users).filter(u => u.id !== activeUser?.id),
          login,
          logout,
          changeUser,
        }),
        [activeUser, changeUser, login, logout, users],
      )}
    >
      {children}
    </Provider>
  );
};

export default UserProvider;
