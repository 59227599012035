import i18n from "i18next";
// import i18n from "i18n";
const namespaceRegex = /@ns\s(?<ns>[^\n]+)/im;
const keyRegex = /\s*@key\s(?<key>[^\n]+)/im;
const defRegex = /@def\s(?<def>[^\n]+)/im;
const pluralRegex = /@plural/im;

const translator = <T extends Record<string, string | number | boolean> | undefined>(
  strings: TemplateStringsArray,
  params?: T,
): string => {
  let match: RegExpExecArray | null;
  let extracted = "";
  const combined = strings.join("");

  while ((match = keyRegex.exec(combined)) !== null) {
    if (match?.groups?.key) {
      extracted = match.groups.key;
      break;
    }
  }

  if (!extracted) {
    extracted = combined;
    // throw new Error("No @key found in template string");
  }

  let plural = false;
  const pluralMatch = pluralRegex.exec(combined);

  if (pluralMatch) {
    plural = true;
    extracted = extracted.replace(pluralRegex, "").trim();
  }

  if (!extracted.includes("|")) {
    // check if @def is used
    const defMatch = defRegex.exec(combined);

    if (defMatch?.groups?.def) {
      extracted = `${extracted}|${defMatch.groups.def}`;
    }
  }

  let [key, defaultValue] = extracted.split("|");
  let namespace: string | undefined = undefined;
  const namespaceMatch = namespaceRegex.exec(combined);

  if (namespaceMatch?.groups?.ns) {
    namespace = namespaceMatch.groups.ns;
  }

  for (const paramName in params) {
    if (Object.prototype.hasOwnProperty.call(params, paramName)) {
      // replace {paramName} with params[key]
      key = key.replace(`{${paramName}}`, params[paramName].toString());
      defaultValue = defaultValue?.replace(`{${paramName}}`, params[paramName].toString());
    }
  }

  if (plural) {
    key = `${key}_plural`;
  }

  // eslint-disable-next-line import/no-named-as-default-member
  return i18n.t(key, {
    defaultValue,
    ns: namespace,
    ...params,
  }) as string;
};

declare global {
  interface Window {
    t: typeof translator;
  }

  const t: typeof translator;
}
window.t = translator;
