import { PureComponent, type ReactNode } from "react";

interface FallbackParameters {
  cause?: string;
}

export interface ErrorBoundaryProps extends FallbackParameters {
  children: ReactNode;
  onErrorEffect?: (error: Error, params?: FallbackParameters) => void;
  getJsxForError?: (
    error: Error,
    params?: FallbackParameters & {
      children: ReactNode;
    },
  ) => ReactNode;
  noLogout?: boolean;
}

interface State {
  error?: Error;
}

class ErrorBoundary extends PureComponent<ErrorBoundaryProps, State> {
  state: State = { error: undefined };
  static getDerivedStateFromError(error: Error): State {
    return { error };
  }
  componentDidCatch(): void {
    const { error } = this.state;

    if (error) {
      const { onErrorEffect, cause, ...rest } = this.props;
      onErrorEffect?.(error, { cause, ...rest });
    }
  }
  render(): ReactNode {
    const { children, getJsxForError, cause, ...rest } = this.props;
    const { error } = this.state;

    if (error) {
      if (getJsxForError) {
        return getJsxForError(error, { cause, children, ...rest });
      }
    }

    return children;
  }
}
export default ErrorBoundary;
