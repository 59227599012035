import type { DeepPartial } from "@panel2/schedule-model";
import type Permissions from "model/Permissions";
import type { BaseUser } from "model/User";
import { createCtx } from "util/context";
export const [usePermissions, PermissionsProvider] = createCtx<DeepPartial<Permissions["root"]>>();
export const [useCurrentUser, CurrentUserProvider] = createCtx<
  BaseUser & {
    companyId: string;
  }
>();
