import "components/atoms/module_loader/style.css";
import { Spinner } from "@panel2/systail-ui/spinner";
import { type ReactNode, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { createRoot } from "react-dom/client";
const loaderNode = document.getElementById("module-loader");
const root = loaderNode && createRoot(loaderNode);

interface Props {
  message: ReactNode;
}

if (loaderNode && root) {
  loaderNode.dataset.active = "0";

  root.render(
    <div className="fixed top-[50%] flex h-full w-full flex-col">
      <div className="mx-auto flex flex-col p-3">
        <Spinner style={{ fontSize: "2rem" }} className="m-auto" />
        <div
          id="module-loader-message"
          className="text-dim mx-auto mt-2 flex flex-col text-center"
        />
      </div>
    </div>,
  );
}

let messageNode: HTMLElement | null = null;

const ModuleLoader = ({ message }: Props) => {
  const timerRef = useRef<number>(0);

  useEffect(() => {
    clearTimeout(timerRef.current);

    if (!loaderNode) {
      console.error("Failed to find the loader node");

      return;
    }

    const activeLoaders = Number.parseInt(loaderNode.dataset.active || "0", 10) + 1;
    loaderNode.dataset.active = activeLoaders.toString();

    if (activeLoaders > 0) {
      loaderNode.classList.remove("hidden");
      loaderNode.classList.add("flex");
    }

    return () => {
      const activeLoaders = Math.max(0, Number.parseInt(loaderNode.dataset.active || "0", 10) - 1);
      loaderNode.dataset.active = activeLoaders.toString();

      if (activeLoaders === 0) {
        timerRef.current = window.setTimeout(() => {
          loaderNode.classList.add("hidden");
          loaderNode.classList.remove("flex");
        }, 1000);
      }
    };
  }, []);

  if (!loaderNode) {
    return null;
  }

  if (!messageNode) {
    messageNode = document.getElementById("module-loader-message");
  }

  return <>{messageNode && createPortal(message, messageNode)}</>;
};

export default ModuleLoader;
