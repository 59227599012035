import { type FormEventHandler, useCallback } from "react";
import { checkPass, checkPassLength, checkPasswordsMatch } from "util/validators";

import { Button } from "@panel2/systail-ui/button";
import { Input } from "@panel2/systail-ui/form/input";
import { Spinner } from "@panel2/systail-ui/spinner";
import Lock from "components/atoms/icons/Lock";
import { useChangePasswordCtx } from "components/organisms/change_password/provider";

interface Props {
  loading: boolean;
  onSubmit: () => void;
}

const ChangeFromControl = ({ onSubmit, loading }: Props): JSX.Element => {
  const { formOk, pass, passConfirm, setPass, setPassConfirm } = useChangePasswordCtx();

  const submit = useCallback<FormEventHandler>(
    e => {
      e.preventDefault(); // ! prevent reloading !
      onSubmit();
    },
    [onSubmit],
  );

  const passError = checkPass(pass) || checkPassLength(8, pass);

  const passConfirmError = checkPass(pass) || checkPasswordsMatch(pass, passConfirm);

  return (
    <form
      data-cmp="login:form"
      aria-label="login-form-control"
      onSubmit={submit}
      className="flex flex-col"
    >
      <div className="mt-3 flex">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="username-input" className="me-2 flex">
          <Lock size="24px" className="my-auto text-primary" />
        </label>
        <Input
          data-cmp="reset:form:password:input"
          aria-label="password"
          autoComplete="current-password"
          disabled={loading}
          type="password"
          id="password-input"
          className="border-primary-container"
          value={pass ?? ""}
          onChange={({ target }) => setPass(target.value)}
          placeholder={t`placeholders.enter_pass|Enter Password`}
        />
      </div>

      {!!passError && (
        <small data-cmp="reset:form:password:error" className="text-right text-rose-500">
          {t<{
            passError: string;
          }>`labels.passError|{passError} ${{
            passError,
          }}`}
        </small>
      )}

      <div className="mt-2 flex">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="password-input" className="me-2 flex">
          <Lock size="24px" className="my-auto text-primary" />
        </label>
        <Input
          data-cmp="reset:form:password-confirm:input"
          aria-label="password"
          autoComplete="current-password"
          disabled={loading}
          type="password"
          id="password-confirm-input"
          className="border-primary-container"
          value={passConfirm ?? ""}
          onChange={({ target }) => setPassConfirm(target.value)}
          placeholder={t`placeholders.enter_pass_confirm|Enter Password Confirmation`}
        />
      </div>

      {!!passConfirmError && (
        <small data-cmp="reset:form:password-confirm:error" className="text-right text-rose-500">
          {t<{
            passConfirmError: string;
          }>`labels.passConfirmError|{passConfirmError} ${{
            passConfirmError,
          }}`}
        </small>
      )}

      <div className="mt-4 flex justify-end">
        <Button
          data-cmp="login:form:submit:button"
          aria-label="submit"
          type="submit"
          disabled={!formOk}
          className="mt-auto flex"
          $variant="primary"
        >
          {loading ? (
            <Spinner className="my-auto" />
          ) : (
            <span className="my-auto">{t`labels.submit|Submit`}</span>
          )}
        </Button>
      </div>
    </form>
  );
};

export default ChangeFromControl;
