import { getRawUser } from "components/hoc/user/provider";
import SessionExpiredException from "exceptions/session_expired";
import type { DateTimeFormat } from "model/util";
import { JWTExpiresIn } from "util/misc";

const noTokens: [
  string,
  string | undefined,
  (
    | {
        token: string;
        expireAt: DateTimeFormat;
      }
    | undefined
  ),
] = ["", undefined, undefined]; // not to create new ref every time

export const getTokens = (
  check = true,
): [
  string,
  string | undefined,
  (
    | {
        token: string;
        expireAt: DateTimeFormat;
      }
    | undefined
  ),
] => {
  // const store = getTokenStorage();
  // const obfuscated = store.getItem(obfuscate(TOKEN_STORAGE_KEY));
  // const saved = obfuscated !== null ? deobfuscate(obfuscated) : undefined;
  // const parsed = !!saved && JSON.parse(saved);
  // get from cookie
  const userData = getRawUser();
  const tokens = userData?.tokens;

  if (tokens && Array.isArray(tokens) && tokens.length >= 2) {
    if (check) {
      return JWTExpiresIn(tokens[0]) > 60000 ? tokens : noTokens;
    }

    return tokens;
  }

  return noTokens;
};

export const getToken = (): string | undefined => {
  try {
    const [token] = getTokens(true);

    if (token) {
      return token;
    }

    throw new SessionExpiredException("No token");
  } catch {
    throw new SessionExpiredException("Session expired");
  }
};
