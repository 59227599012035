import { useCallback, useEffect, useRef } from "react";

type Fun =
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  (...args: any) => any;

const useCallbackRef = <T extends Fun>(
  callback: T,
): ((...args: Parameters<T>) => ReturnType<T>) => {
  const ref = useRef<T>(callback);

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  return useCallback((...args: any): ReturnType<T> => ref.current(...args), []);
};

export default useCallbackRef;
