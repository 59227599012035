import { getProtectedApi } from "api/common";
import { type Abortable, request } from "api/utils";
import type Permissions from "model/Permissions";
import type { DeepPartial } from "model/util";
import { BASE_API_URL } from "util/const";
export const getPermissionsTree = (): Abortable =>
  request(`${BASE_API_URL}/permissions/root`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  });
export const getDataPermissionsTree = (): Abortable =>
  request(`${BASE_API_URL}:endpoint`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  });
export const getPermissions = (ns?: string) =>
  getProtectedApi()
    .get<DeepPartial<Permissions>>(`/permissions${ns ? `/${ns}` : ""}`)
    .then(r => r.data);
