import Exception from "exceptions/base";
import { LAST_URL_KEY } from "util/const";
export default class SessionExpiredException extends Exception {
  type = "expired";
  name = "SessionExpiredException";
  constructor(message: string) {
    super(message);
    const lastUrl = sessionStorage.getItem(LAST_URL_KEY);

    if (!lastUrl && !window.location.pathname.includes("logout")) {
      // set only once (first) even if multiple errors were thrown
      sessionStorage.setItem(LAST_URL_KEY, window.location.pathname + window.location.search);
    }
  }
}
