import "components/templates/error/error/style.css";
import { Button } from "@panel2/systail-ui/button";
import { ContextMenu, MenuButton, MenuHeader } from "@panel2/systail-ui/menu";
import { Modal } from "@panel2/systail-ui/modal";
import clsx from "clsx";
import ExclamationTriangle from "components/atoms/icons/ExclamationTriangle";
import FetchException from "exceptions/fetch";
import { type ReactNode, useMemo, useState } from "react";

interface Props {
  error?: Error | FetchException;
  size?: string;
  cause?: string | ReactNode;
  className?: string;
  /** @deprecated */
  horizontal?: boolean;
  onClick?: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const ErrorCmp = ({ size = "2em", error, cause, className, onClick }: Props): JSX.Element => {
  const [showStack, setShowStack] = useState<boolean>(false);
  let message = t`
    @key unknown_error|Unknown error
    @ns error
  `;
  let stack: string | undefined = undefined;

  if (error instanceof FetchException && (error?.source?.message ?? error?.message)) {
    message = error.source?.message ?? error.message;
    stack = error.source?.stack ?? error.stack;
  } else if (error?.message) {
    message = t<{
      error: "Error" | "SessionExpiredException" | string;
      message: string;
    }>`errors.{error}|{error}: {message}${{
      error: error.name ?? "unknown",
      message: error.message,
    }}`;

    stack = error.stack;
  }

  const text = useMemo(() => {
    const msg = message ? (
      <small>{message}</small>
    ) : (
      <small className="max-w-full text-wrap break-words leading-none">{message}</small>
    );

    return (
      <div className="max-w-[340px]">
        {cause ? (
          <>
            {typeof cause === "string" ? (
              <div className="flex flex-col">
                <div className="mb-2 flex">
                  <ExclamationTriangle
                    size={size}
                    style={{
                      minWidth: size,
                      minHeight: size,
                    }}
                    className={clsx(
                      "mb-auto block h-full text-rose-500",
                      !!onClick && "warning-icon",
                    )}
                  />
                  <strong className="my-auto ml-3">{cause}</strong>
                </div>

                {msg}
              </div>
            ) : (
              cause
            )}
          </>
        ) : (
          msg
        )}
      </div>
    );
  }, [cause, message, onClick, size]);

  return (
    <ContextMenu
      role="button"
      disabled={!stack || process.env.NODE_ENV === "production"}
      interactive={false}
      menu={
        <>
          <MenuHeader>
            <span>{t`labels.error|Error`}</span>
          </MenuHeader>
          <MenuButton
            onClick={e => {
              e.stopPropagation();
              setShowStack(!showStack);
            }}
          >
            <span>{t`labels.show_error_stack_trace|Show stack trace`}</span>
          </MenuButton>
        </>
      }
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        className={clsx(
          "flex rounded bg-surface-container p-3",
          className,
          !!onClick && "refresh cursor-pointer",
        )}
        onClick={e => onClick?.(e)}
      >
        {text}

        <Modal visible={showStack} onVisibleChange={setShowStack} className="flex flex-col">
          <pre
            className="m-0 grow whitespace-normal"
            style={{
              maxWidth: "min(90vw, 700px)",
              fontSize: "0.7em",
            }}
          >
            {stack}
          </pre>

          <Button $variant="secondary" className="ml-auto mt-2" onClick={() => setShowStack(false)}>
            <span>{t`labels.close|Close`}</span>
          </Button>
        </Modal>
      </div>
    </ContextMenu>
  );
};

export default ErrorCmp;
