import Exception from "exceptions/base";
import type { ErrorType } from "hooks/fetch/useFetch";
export class RequestPendingException<E extends ErrorType = ErrorType> extends Exception {
  type = "request_pending";
  source?: Error;
  message = "RequestPendingException";
  response?: {
    status?: number;
    data?: E;
  };
  constructor(
    source?: Error,
    response?: {
      status?: number;
      data?: E;
    },
  ) {
    super(source?.message);
    this.source = source;
    this.response = response;
  }
}
export default class FetchException<E extends ErrorType = ErrorType> extends Exception {
  type = "fetch";
  source?: Error;
  message = "FetchException";
  response?: {
    status?: number;
    data?: E;
  };
  constructor(
    source?: Error,
    response?: {
      status?: number;
      data?: E;
    },
  ) {
    super(source?.message);
    this.message = source?.message || this.message;
    this.source = source;
    this.response = response;
  }
}
