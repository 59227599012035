import clsx from "clsx";
import { Provider } from "components/hoc/snack_message/provider";
import { type ReactNode, useCallback, useEffect, useState } from "react";
import { mapNumberToRange } from "util/misc";
export type SnackMessageContext = (
  notification: string,
  variant?: "error" | "warning" | "info" | "success",
) => void;

interface Props {
  children: ReactNode;
}

const SnackMessageProvider = ({ children }: Props): JSX.Element => {
  const [queue, setQueue] = useState<
    {
      message: string;
      variant: "error" | "warning" | "info" | "success" | undefined;
    }[]
  >([]);

  const pushSnackMessage = useCallback(
    (message: string, variant?: "error" | "warning" | "info" | "success") =>
      setQueue(old =>
        [
          ...old,
          {
            message,
            variant,
          },
        ].slice(0, 10),
      ),
    [setQueue],
  );

  useEffect(() => {
    if (queue.length > 0) {
      const timer = setTimeout(
        () => setQueue(old => old.slice(1)),
        mapNumberToRange(queue.length, 1, 10, 3000, 1000),
      );

      return () => clearTimeout(timer);
    }
  }, [queue]);

  return (
    <Provider value={pushSnackMessage}>
      {children}

      {queue.length > 0 && (
        <div
          style={{
            width: "min(100vw, 350px)",
            left: "calc(50% - calc(min(100vw, 350px) / 2))",
            bottom: 25,
            zIndex: 1000,
          }}
          className="delayed-grow-sm fixed flex bg-transparent"
          role="alert"
        >
          <span
            style={{ opacity: 0.9, fontSize: "0.9rem", borderRadius: 25 }}
            className={clsx(
              "m-auto border-2 bg-surface-container px-2 py-0 text-center",
              queue[0].variant ? `border-${queue[0].variant}` : "border-secondary",
            )}
          >
            {queue[0].message}
          </span>
        </div>
      )}
    </Provider>
  );
};

export default SnackMessageProvider;
