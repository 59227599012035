const bg = `<svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin slice" width="100%" height="100%"
    viewBox="0 0 508 285.75">
    <g transform="translate(64.7 -88.712)">
        <path fill-rule="evenodd" d="M-64.7 88.712h508v285.75h-508z"
            style="fill: rgb(var(--svg-bg)); fill-opacity: 1" />
        <g fill="rgb(var(--svg-strips-fill))" fill-opacity=".118" fill-rule="evenodd"
            transform="translate(-64.7 88.712)">
            <rect id="strips-bottom-horiz" width="119.238" height="2.267" x="241.039" y="258.588"
                ry="1.133" />
            <rect id="strips-bottom-vert" width="119.238" height="2.267" x="-279.95" y="337.794"
                ry="1.133"
                transform="rotate(-90)" />
        </g>
        <path id="digital-signage-box-shadow" fill-opacity=".643" fill-rule="evenodd"
            d="M197.915 156.402a4.171 4.171 0 0 0-4.172 4.19v140.591c0 2.321 1.86 4.19 4.172 4.19H443.3V156.401z"
            style="fill: rgb(var(--svg-digital-signage-box-fill)); fill-opacity: 0.643" />
        <path id="digital-signage-box" fill-rule="evenodd"
            d="M194.403 153.16a4.148 4.148 0 0 0-4.158 4.157v139.498a4.148 4.148 0 0 0 4.158 4.157H443.3V153.16z"
            style="fill: rgb(var(--svg-digital-signage-box-fill)); fill-opacity: 1" />
        <g fill="rgb(var(--svg-strips-fill))" fill-opacity=".118" fill-rule="evenodd"
            transform="rotate(180 222.514 192.566)">
            <rect id="strips-top-horiz" width="119.238" height="2.267" x="241.039" y="258.588"
                ry="1.133" />
            <rect id="strips-top-horiz" width="119.238" height="2.267" x="-279.95" y="337.794"
                ry="1.133"
                transform="rotate(-90)" />
        </g>

        <rect id="middle-box-shadow" width="163.989" height="89.383" x="9.257" y="173.914"
            fill="rgb(var(--svg-middle-box-fill))" fill-opacity=".621" fill-rule="evenodd"
            ry="6.822" />
        <rect id="middle-box" width="163.989" height="89.383" x="-7.041" y="166.892"
            fill="rgb(var(--svg-middle-box-fill))" fill-rule="evenodd" ry="6.822" />

        <path id="systail-box-shadow"
            d="M-64.7 88.712v91.86H90.733a7.575 7.575 0 0 0 7.593-7.592V88.712Z"
            style="fill: rgb(var(--svg-systail-box-fill)); fill-opacity: 0.649; stroke-width: 1" />
        <path id="systail-box" d="M-64.7 88.712v83.026H79.905a7.575 7.575 0 0 0 7.592-7.592V88.712Z"
            style="fill: rgb(var(--svg-systail-box-fill)); fill-opacity: 1; stroke-width: 1" />

        <rect id="small-box-shadow" width="20.583" height="20.583" x="156.229" y="291.531"
            fill-rule="evenodd" ry="2.068"
            style="fill: rgb(var(--svg-small-box-fill)); fill-opacity: 0.5" />
        <rect id="small-box" width="16.783" height="16.783" x="153.661" y="290.283"
            fill-rule="evenodd" ry="2.59"
            style="fill-opacity: 1" fill="rgb(var(--svg-small-box-fill))" />

        <rect id="vertical-box-shadow" width="163.989" height="89.383" x="-367.635" y="-22.576"
            fill-opacity=".606"
            fill-rule="evenodd" ry="6.822"
            style="fill: rgb(var(--svg-vertical-box-fill)); fill-opacity: 0.606"
            transform="rotate(-90)" />
        <rect id="vertical-box" width="163.989" height="89.383" x="-358.953" y="-32.155"
            fill-rule="evenodd" ry="6.822"
            style="fill: rgb(var(--svg-vertical-box-fill)); fill-opacity: 1" transform="rotate(-90)" />

        <!-- vertical-box-strips -->
        <rect width="29.296" height="2.42" x="-22.576" y="203.646"
            fill="rgb(var(--svg-vertical-svg-box-strips-fill))"
            fill-opacity=".606" fill-rule="evenodd" ry="1.21" />
        <rect width="32.094" height="2.42" x="-22.576" y="207.946"
            fill="rgb(var(--svg-vertical-svg-box-strips-fill))"
            fill-opacity=".606" fill-rule="evenodd" ry="1.21" />
        <rect width="22.265" height="2.42" x="-22.576" y="212.245"
            fill="rgb(var(--svg-vertical-svg-box-strips-fill))"
            fill-opacity=".606" fill-rule="evenodd" ry="1.21" />

        <!-- middle-box-strips -->
        <rect width="29.296" height="2.42" x="117.034" y="235.843"
            fill="rgb(var(--svg-middle-box-strips-fill))"
            fill-rule="evenodd" ry="1.21" />
        <rect width="32.094" height="2.42" x="114.236" y="240.142"
            fill="rgb(var(--svg-middle-box-strips-fill))"
            fill-rule="evenodd" ry="1.21" />
        <rect width="22.265" height="2.42" x="124.064" y="244.441"
            fill="rgb(var(--svg-middle-box-strips-fill))"
            fill-rule="evenodd" ry="1.21" />

        <!-- small-box-strips -->
        <g fill="rgb(var(--svg-small-box-strips-fill))" fill-rule="evenodd"
            transform="translate(-64.7 88.231)">
            <rect width="10.974" height=".907" x="221.266" y="208.379" ry=".453" />
            <rect width="12.022" height=".907" x="220.742" y="209.99" ry=".453" />
            <rect width="8.34" height=".907" x="222.583" y="211.6" ry=".453" />
        </g>

        <text font-size="24" transform="translate(10 160)" style="line-height: 1.25">
            <tspan style="fill: rgb(var(--svg-systail-text-fill))">systail</tspan>
        </text>

        <text font-size="20" style="line-height: 1.25" transform="rotate(-90 245.822 85.05)"
            fill="rgb(var(--svg-digital-text-fill))">
            <tspan x="40" y="50">
                <tspan>Digital</tspan>
            </tspan>
        </text>

        <text font-size="52" style="line-height: 1.25" transform="translate(59.96 83.64)"
            fill="rgb(var(--svg-signage-text-fill))">
            <tspan x="160" y="200">
                <tspan>Signage</tspan>
            </tspan>
        </text>
    </g>
</svg>`;

export default bg;
