import { useUserCtx } from "components/hoc/user/provider";
import { CurrentUserProvider } from "components/router/provider";
import { useSlug } from "provider";
import type { ReactNode } from "react";
import { Navigate } from "react-router-dom";

const Authorized = ({ children }: { children: ReactNode }): JSX.Element => {
  const { user } = useUserCtx();
  const slug = useSlug();

  if (!user) {
    // user is not authenticated
    return <Navigate to={`/${slug}/login`} />;
  }

  if (user.passwordChangeRequired) {
    return <Navigate to={`/${slug}/user/change-password`} />;
  }

  return <CurrentUserProvider value={user}>{children}</CurrentUserProvider>;
};

export default Authorized;
