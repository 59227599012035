import type { QueryParams } from "api/utils";
import { useInternalQueryState } from "components/hoc/query/provider";
import type { PaginationState } from "model/util";
import { type Dispatch, type SetStateAction, useMemo } from "react";

/**
 * Stores state in url params and triggers navigation change on state change.
 * This causes all the components to re-render, but allows to record history of changes.
 * If you only want to store state in url params, but not trigger navigation change, use useQueryWithoutNavigation hook.
 */
export const useSlowQueryStateWithNavigationChange = <
  T extends Partial<QueryParams | PaginationState> = QueryParams,
>(): [T, Dispatch<SetStateAction<T>>] => {
  const [state, setState] = useInternalQueryState();

  return useMemo(() => [state as T, setState as Dispatch<SetStateAction<T>>], [setState, state]);
};
