import { AxiosError } from "axios";
import { DateTime } from "luxon";
import type { FormikErrors } from "formik";
import type { DateTimeFormat as IDateTime } from "model/util";
import type { NotificationData } from "model/Notification";
import { THEME_STORAGE_KEY } from "util/const";
import { blendHexToTheme } from "@panel2/systail-ui/util";
import { formatParam } from "components/organisms/layouts/editor/contexts/layout_provider/sources_provider/utils";
// interface EncodeQueryDataParameters {
//   searchString: string
//   limit: number
//   page: number
// }
export type ValueOf<T> = T[keyof T];
// export const flattenRecord = (
//   record: Record<string, unknown>
// ): Record<string, unknown> => {
//   const result: Record<string, unknown> = {}
//   for (const [key, value] of Object.entries(record)) {
//     if (typeof value === "object" && value !== null) {
//       for (const [subKey, subValue] of Object.entries(flattenRecord(value as Record<string, unknown>))) {
//         result[`${key}.${subKey}`] = subValue
//       }
//     } else {
//       result[key] = value
//     }
//   }
//   return result
// }
export const fixNaNInputVal = (val: number | undefined): number | string =>
  val === undefined || Number.isNaN(val) ? "" : val;

export const flattenFormikErrorsToArray = <T>(errors: FormikErrors<T> | null): string[] => {
  return Object.values(flattenFormikErrors(errors));
};

export const flattenFormikErrors = <T>(
  errors: FormikErrors<T> | null,
  prefix = "",
): Record<string, string> => {
  const result: Record<string, string> = {};

  const entries = Object.entries(errors || {})
    .flatMap(([key, value]) => {
      const newKey = `${prefix ? `${prefix}.` : ""}${key}`;

      return typeof value === "object" ? flattenFormikErrors(value, newKey) : { [newKey]: value };
    })
    .filter(
      (p): p is Record<string, string> =>
        !!p &&
        typeof p === "object" &&
        !Array.isArray(p) &&
        Object.values(p).every(v => typeof v === "string"),
    );

  for (const entry of entries) {
    for (const [key, value] of Object.entries(entry)) {
      result[key] = value;
    }
  }

  return result;
};

export const clearSavedState = (): void => {
  const theme = localStorage.getItem(THEME_STORAGE_KEY);
  const getKnownSlugs = localStorage.getItem("known_slugs");
  localStorage.clear();
  sessionStorage.clear();

  if (getKnownSlugs) {
    localStorage.setItem("known_slugs", getKnownSlugs);
  }

  if (theme) localStorage.setItem(THEME_STORAGE_KEY, theme);
};

export const JWTExpirationDate = (jwtToken: string): number => {
  if (!jwtToken) return -1;

  const jwt = JSON.parse(window.atob(jwtToken.split(".")[1]));

  if (jwt?.exp) {
    // multiply by 1000 to convert seconds into milliseconds
    return jwt.exp * 1000;
  }

  return -1;
};

export const percentageToHsl = (
  percentage: number,
  hue0: number,
  hue1: number,
  saturation = 100,
  opacity = 50,
) => {
  const hue = (percentage / 100) * (hue1 - hue0) + hue0;

  return `hsl(${hue},${saturation}%,${opacity}%)`;
};

export const JWTExpiresIn = (token: string): number => {
  try {
    const jwtExp: number = JWTExpirationDate(token);

    console.info(
      `jwt expires ${DateTime.fromMillis(jwtExp).diffNow().toHuman({ listStyle: "short" })}`,
    );

    return jwtExp - new Date().getTime();
  } catch {
    return 0;
  }
};

export const notifyError = (
  notify: (notification: NotificationData) => void,
  title: JSX.Element | string | string[],
  error?: Error,
): void => {
  if (error instanceof AxiosError) {
    if (error.response?.data?.error || error.response?.data?.message) {
      error.message = error.response.data.error || error.response.data.message;
    }
  }

  notify({
    variant: "error",
    title,
    content: error?.message
      ? error.message.includes("|")
        ? error.message
        : `error@${error.message}|${error.message}`
      : t`
@key unknown_error_reason|Unknown reason
@ns error
`,
  });
};

export const padNumber = (num: number | string, size: number, padding = "0"): string => {
  let str = num.toString();
  while (str.length < size) str = padding + str;

  return str;
};

export const mapNumberToRange = (
  num: number,
  in_min: number,
  in_max: number,
  out_min: number,
  out_max: number,
): number => {
  return ((num - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
};

export const now = (): IDateTime =>
  DateTime.now().set({ millisecond: 0 }).toISO({ suppressMilliseconds: true }) as IDateTime; // no idea why null is possible here

export const formatDate = (date: Date | DateTime | IDateTime, format?: string): IDateTime => {
  let luxon: DateTime | undefined;

  if (date instanceof Date) {
    luxon = DateTime.fromJSDate(date);
  } else if (date instanceof DateTime) {
    luxon = date;
  } else {
    luxon = dateFromStr(date);
  }

  return format
    ? luxon.toFormat(format, { locale: "en" })
    : (luxon.set({ millisecond: 0 }).toISO({ suppressMilliseconds: true }) as IDateTime);
};

export const dateFromStr = (dateTime: IDateTime): DateTime => DateTime.fromISO(dateTime);
export const isModified = <A, B = A>(a: A, b: B) => (a ?? b) !== b;

export const fitMaxInPowerOfTwo = (powerOfTwo: number, maxPower: number): number => {
  if (maxPower < 0 || powerOfTwo < 1) {
    return -1;
  }

  if (2 ** maxPower <= powerOfTwo) {
    return maxPower;
  }

  return fitMaxInPowerOfTwo(powerOfTwo, maxPower - 1);
};

export const snakeToCamelCase = (s: string | number): string => {
  return String(s).replace(/([-_][a-z])/gi, ($1: string) => {
    return $1.toUpperCase().replace("-", "").replace("_", "");
  });
};

export const castType = (value: string | number): string | number | boolean | undefined => {
  let result: string | number | boolean | undefined = undefined;

  if (!!value && !Number.isNaN(Number(value))) {
    result = Number(value);
  } else if (typeof value === "number") {
    result = value;
  } else if (value === "true") {
    result = true;
  } else if (value === "false") {
    result = false;
  } else if (value === "NaN") {
    result = Number.NaN;
  } else if (value === "Infinity") {
    result = Number.POSITIVE_INFINITY;
  } else if (value === "-Infinity") {
    result = Number.NEGATIVE_INFINITY;
  } else if (value.match(/^\d+$/)) {
    result = Number.parseInt(value, 10);
  } else if (value.match(/^\d+\.\d+$/)) {
    result = Number.parseFloat(value);
  } else if (value === "null" || value === "undefined") {
    result = undefined;
  } else {
    result = value;
  }

  return result;
};

export const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export const truncate = (str: string, length: number, noEnding?: boolean, ending = "...") => {
  if (str.length > length) {
    return str.substring(0, noEnding ? length : length - ending.length) + ending;
  }

  return str;
};

export const loadFont = (url: string) => {
  const node = document.createElement("style");
  node.innerHTML = `@import url('${url}');`;
  document.head.appendChild(node);
};

export function padZero(str: string, len = 2) {
  const zeros = new Array(len).join("0");

  return (zeros + str).slice(-len);
}

export const invertHex = (hex: string, bw: boolean): string => {
  let hexCopy = hex;

  if (hex.indexOf("#") === 0) {
    hexCopy = hexCopy.slice(1);
  }

  // convert 3-digit hexCopy to 6-digits.
  if (hexCopy.length === 3) {
    hexCopy = hexCopy[0] + hexCopy[0] + hexCopy[1] + hexCopy[1] + hexCopy[2] + hexCopy[2];
  }

  if (hexCopy.length !== 6) {
    throw new Error("Invalid HEX color.");
  }

  let r = Number.parseInt(hexCopy.slice(0, 2), 16);
  let g = Number.parseInt(hexCopy.slice(2, 4), 16);
  let b = Number.parseInt(hexCopy.slice(4, 6), 16);

  if (bw) {
    // https://stackoverflow.com/a/3943023/112731
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#FFFFFF";
  }

  // invert color components
  r = 255 - r;
  g = 255 - g;
  b = 255 - b;

  // pad each with zeros and return
  return `#${padZero(r.toString(16))}${padZero(g.toString(16))}${padZero(b.toString(16))}`;
};

export const getColorsForString = (
  str: string,
  lighten = 50,
  blendToColor?: {
    r: number;
    g: number;
    b: number;
    a: number;
  },
): {
  bg: string;
  fg: string;
} => {
  let hash = 0;

  for (const char of str.split("")) {
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  }

  let color = "#";

  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 255;
    color += value.toString(16).padStart(2, "0");
  }

  color = `#${lightenHex(color.replace("#", ""), lighten)}`;

  const blended = blendToColor
    ? blendHexToTheme(
        {
          r: Number.parseInt(color.slice(1, 3), 16),
          g: Number.parseInt(color.slice(3, 5), 16),
          b: Number.parseInt(color.slice(5, 7), 16),
          a: 1,
        },
        blendToColor,
      )
    : undefined;

  if (blended) {
    color = `#${padZero(blended.r.toString(16))}${padZero(
      blended.g.toString(16),
    )}${padZero(blended.b.toString(16))}`;
  }

  return { bg: color, fg: invertHex(color, true) };
};

const lightenHex = (color: string, percent: number) => {
  const num = Number.parseInt(color, 16);
  const amt = Math.round(2.55 * percent);
  const R = (num >> 16) + amt;
  const B = ((num >> 8) & 255) + amt;
  const G = (num & 255) + amt;

  return (
    16777216 +
    (R < 255 ? (R < 1 ? 0 : R) : 255) * 65536 +
    (B < 255 ? (B < 1 ? 0 : B) : 255) * 256 +
    (G < 255 ? (G < 1 ? 0 : G) : 255)
  )
    .toString(16)
    .slice(1);
};

export const replaceUrlParams = async (
  url: string,
  params: Record<string, string | unknown | undefined>,
) => {
  let urlCopy = url;
  let match: RegExpExecArray | null;
  const regex = /\{(\w+)\}/;

  // biome-ignore lint/suspicious/noAssignInExpressions: <explanation>
  while ((match = regex.exec(urlCopy)) !== null) {
    const param = match[1] as string;
    const hash = await formatParam(params[param]);

    if (!hash) {
      console.warn("LayoutSourcesProvider", `Missing param ${param} hash!`);

      return undefined;
    }

    urlCopy = urlCopy.replace(
      // {param} -> params[param]
      `{${param}}`,
      hash,
    );
  }

  return urlCopy;
};
