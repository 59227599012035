import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useMemo,
  useReducer,
  useState,
} from "react";
import { DEFAULT_STORAGE, PERSISTED_STATE_PREFIX } from "util/const";
export type PersistedReducer<T, ActionType> = (state: T, action: ActionType) => T;

const get = (storageKey: string, storage: Storage) => {
  let persisted = undefined;

  try {
    persisted = JSON.parse(storage.getItem(storageKey) || "");

    if (persisted === null) persisted = undefined;
  } catch {
    //
  }

  return persisted;
};

export const usePersistedReducer = <T, ActionType>(
  storageKey: string | undefined,
  reducer: PersistedReducer<T, ActionType>,
  defaultState?: T,
  storage: Storage = DEFAULT_STORAGE,
  prefix: string = PERSISTED_STATE_PREFIX,
): [T, Dispatch<ActionType>] => {
  const initial: T = useMemo(() => {
    const saved = storageKey && get(`${prefix}${storageKey}`, storage);

    return saved === undefined ? defaultState : saved;
  }, [defaultState, prefix, storage, storageKey]);

  const [state, _dispatch] = useReducer(reducer, initial);

  const dispatch = useCallback(
    (action: ActionType) => {
      const next = _dispatch(action);

      if (storageKey) storage.setItem(`${prefix}${storageKey}`, JSON.stringify(next));
    },
    [storageKey, storage, prefix],
  );

  return [state ?? initial, dispatch];
};

const usePersistedState = <T>(
  storageKey: string | undefined,
  defaultState?: T,
  storage: Storage = DEFAULT_STORAGE,
  prefix: string = PERSISTED_STATE_PREFIX,
): [T, Dispatch<SetStateAction<T>>] => {
  const [state, setState] = useState<T>(() => {
    const saved = storageKey && get(`${prefix}${storageKey}`, storage);

    return saved === undefined ? defaultState : saved;
  });

  const update = useCallback(
    (next: T | SetStateAction<T>) => {
      setState((old: T) => {
        const calculated = next instanceof Function ? next(old) : next;

        if (storageKey) storage.setItem(`${prefix}${storageKey}`, JSON.stringify(calculated));

        return calculated;
      });
    },
    [storageKey, storage, prefix],
  );

  return [state, update];
};

export default usePersistedState;
