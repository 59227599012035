import { Alert } from "@panel2/systail-ui/alert";
import clsx from "clsx";
import type INotification from "model/Notification";
import { type MouseEvent, useCallback, useEffect, useRef } from "react";

interface Props {
  notification: INotification;
  onClose: () => void;
  [key: string]: unknown;
}

const Notification = ({
  notification: {
    title,
    content,
    createdAt,
    variant = "primary",
    timeout,
    props,
    onDismiss,
    onTimeout,
    onClick,
  },
  onClose,
  ...rest
}: Props): JSX.Element => {
  const timer = useRef<number>(0);

  const close = useCallback(
    (e?: MouseEvent) => {
      if (e) e.stopPropagation();

      onClose();

      if (onDismiss) onDismiss();
    },
    [onClose, onDismiss],
  );

  const click = useCallback(() => {
    if (onClick) onClick();
  }, [onClick]);

  useEffect(() => {
    let t = timeout;

    if (!t) {
      if (variant === "error") {
        return;
      }

      t = 4000;
    }

    timer.current = window.setTimeout(() => {
      if (onTimeout) onTimeout();

      close();
    }, t);
  }, [close, onTimeout, timeout, variant]);

  useEffect(
    () => () => {
      window.clearTimeout(timer.current);
    },
    [],
  );

  return (
    <Alert
      className={clsx(
        "delayed-grow-sm mx-3 mb-2 flex min-w-[100px]",
        !variant && "bg-surface-variant",
        props?.className,
      )}
      title={title}
      onClick={click}
      onDismiss={close}
      date={createdAt}
      $variant={
        variant === "warning"
          ? "secondary"
          : variant === "success"
            ? "primary"
            : variant === "info"
              ? "none"
              : variant
      }
      {...rest}
    >
      {!!content && (
        <small className="block p-2">
          {typeof content === "function" ? content(close) : content}
        </small>
      )}
    </Alert>
  );
};

export default Notification;
