import { useMutation } from "@tanstack/react-query";
import { mercurePush } from "api/mercure";
import { EventSourcePolyfill } from "event-source-polyfill";
import useCallbackRef from "hooks/callback_ref/useCallbackRef";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { MERCURE_HUB_URL } from "util/const";
import { getTokens } from "util/token";

type Return<T> = {
  push: (data: T) => void;
  eventSource?: EventSource;
  error?: Error;
};
type Options = {
  publishToken?: string;
  disable?: boolean;
  lastMessageId?: string;
  onError?: (error: Error) => void;
};

const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  if (parts.length === 2) {
    return parts.pop()?.split(";").shift();
  }
};

const useMercureTopic = <T, R = T>(
  topic: string | undefined,
  onData: (data: R) => void,
  options: Options = {},
): Return<T> => {
  const { 2: mercureToken } = getTokens();
  const onDataRef = useCallbackRef(onData);
  // const notify = useNotifierCtx()
  const eventSourceRef = useRef<EventSourcePolyfill | EventSource | undefined>(undefined);

  const token = useMemo(
    () => getCookie("mercureAuthorization") ?? options.publishToken ?? mercureToken?.token,
    [mercureToken, options.publishToken],
  );

  useEffect(() => {
    if (options.disable || !topic) {
      return;
    }

    const url = new URL(MERCURE_HUB_URL);
    url.searchParams.append("topic", topic);

    if (options.lastMessageId) {
      url.searchParams.append("lastEventID", options.lastMessageId);
    }

    console.info("connecting to mercure", url.toString());

    /**
     * event source wont work on localhost,
     * and polyfill is not good for production
     */
    if (process.env.NODE_ENV === "development") {
      eventSourceRef.current = new EventSourcePolyfill(url.toString(), {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // eventSourceRef.current = new EventSource(url.toString(), {
      //   withCredentials: true,
      // });
    } else {
      eventSourceRef.current = new EventSource(url.toString(), {
        withCredentials: true,
      });
    }

    eventSourceRef.current.onmessage = ({ data }: { data: string }) => onDataRef(JSON.parse(data));

    return () => {
      eventSourceRef.current?.close();
    };
  }, [options.disable, topic, token, onDataRef, options.lastMessageId]);

  const mutation = useMutation({
    mutationFn: mercurePush,
    onSuccess: onDataRef,
    // onError: options.onError ?? ((error: Error) => notifyError(notify, t`labels.could_not_push|Could not push`, error))
    onError: (error: Error) => {
      console.warn("mercure push error", error);
      options.onError?.(error);
    },
  });

  const push = useCallback(
    (data: T) => {
      if (!token) {
        throw new Error("no token present");
      }

      if (!topic) {
        throw new Error("no topic present");
      }

      const body = new URLSearchParams();
      body.append("topic", topic);

      body.append(
        "data",
        JSON.stringify({
          ...data,
          origin: "front",
        }),
      );

      mutation.mutate({ body, token });
    },
    [mutation, token, topic],
  );

  return useMemo(
    () => ({
      push,
      eventSource: eventSourceRef.current,
      error: mutation.error || undefined,
    }),
    [mutation.error, push],
  );
};

export default useMercureTopic;
