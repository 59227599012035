import { Button } from "@panel2/systail-ui/button";
// import { getCompanyCustomFields } from "api/custom_fields/customfields";
import { useQueries } from "@tanstack/react-query";
import { getCompanySettings } from "api/company/settings";
import { getPermissions } from "api/permissions/get";
import {
  getCompanySettingsQueryKey,
  getPermissionsQueryKey,
  getUserSettingsQueryKey,
} from "api/queryKeyStore";
import { getUserSettings } from "api/user/settings";
import ModuleLoader from "components/atoms/module_loader/ModuleLoader";
import { getRawUser } from "components/hoc/user/provider";
import SessionExpiredException from "exceptions/session_expired";
import { type ReactNode, useMemo } from "react";

const usePrefetchEssentialData = (userId?: string) => {
  const results = useQueries({
    queries: [
      {
        enabled: !!userId,
        queryFn: getCompanySettings,
        queryKey: getCompanySettingsQueryKey(userId),
        retry: false,
        refetchInterval: false,
        refetchOnReconnect: false,
      },
      {
        enabled: !!userId,
        queryFn: () => getPermissions("root"),
        queryKey: getPermissionsQueryKey(userId),
        retry: false,
        refetchInterval: false,
        refetchOnReconnect: false,
      },
      {
        enabled: !!userId,
        queryFn: getUserSettings,
        queryKey: getUserSettingsQueryKey(userId),
        retry: false,
        refetchInterval: false,
        refetchOnReconnect: false,
      },
    ],
  });

  const errors = useMemo(
    () =>
      [
        {
          label: t`
@key could_not_get_company_settings|Could not get company settings
@ns error
`,
          error: results[0].error,
        },
        {
          label: t`
@key could_not_get_permissions|Could not get permissions
@ns error
`,
          error: results[1].error,
        },
        {
          label: t`
@key could_not_get_user_settings|Could not get user settings
@ns error
`,
          error: results[2].error,
        },
      ]
        .filter(result => !!result.error && !(result.error instanceof SessionExpiredException))
        .map(result => result.error),
    [results],
  );

  const hasData = useMemo(() => results.every(r => !!r.data), [results]);

  return {
    retry: () => {
      for (const { refetch } of results) {
        refetch();
      }
    },
    errors,
    hasData,
  };
};

const Prefetch = ({ children }: { children: ReactNode }): JSX.Element => {
  const user = getRawUser();
  const res = usePrefetchEssentialData(user?.id);

  return (
    <>
      {res.hasData || !user?.id ? (
        children
      ) : res.errors.length > 0 ? (
        <div className="fixed left-0 top-0 flex h-screen w-screen flex-col bg-black bg-opacity-50">
          <div className="m-auto flex flex-col rounded bg-surface p-3 text-center">
            <span>{t`loading.essential_page_data_error|Failed to load essential page data`}</span>
            {/* {res.errors.map((e, i) => (
              <div className="text-rose-500" key={i}>
                {e instanceof Error ? e.message : `${e}`}
              </div>
            ))} */}

            <Button onClick={res.retry}>
              <span>{t`labels.retry|Retry`}</span>
            </Button>
          </div>
        </div>
      ) : (
        <ModuleLoader message={t`loading.essential_page_data|Loading...`} />
      )}
    </>
  );
};

export default Prefetch;
