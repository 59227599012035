import { camelToSnakeCase, castType, snakeToCamelCase } from "util/misc";

import SessionExpiredException from "exceptions/session_expired";
import { BASE_API_URL } from "util/const";
import { getToken } from "util/token";

export interface ListResponse<T> {
  total: number;
  list: T[];
}
export interface QueryParams {
  [key: symbol | string | number]: symbol | string | number | undefined | boolean;
}
export interface FetchParams extends Omit<RequestInit, "query" | "body"> {
  method?: "GET" | "POST" | "PUT" | "DELETE";
  headers?: {
    [key: string]: string;
  };
  body?: unknown;
  query?: QueryParams;
  urlParams?: QueryParams;
  signal?: AbortSignal;
  ignoreResponse?: boolean;
  timeout?: number;
  onProgress?: (transferred: number, total: number) => void;
}
export class FetchResponse {
  raw: Response;
  constructor(res: Response) {
    this.raw = res.clone();
  }
  get ok(): boolean {
    return this.raw.ok || (this.raw.status >= 200 && this.raw.status < 300);
  }
  clone = () => new FetchResponse(this.raw);
}
export type Executor = (
  overrideParams?: FetchParams,
  silent?: boolean,
) => Promise<FetchResponse | undefined>;
export interface Abortable {
  controller?: AbortController;
  execute: Executor;
}

const xhrFetch = (
  url: string,
  params: FetchParams,
  onProgress?: FetchParams["onProgress"],
): Promise<Response> =>
  new Promise((resolve, reject) => {
    const oReq = new XMLHttpRequest();
    let latency = 0;
    oReq.open(params.method || "GET", url, true);
    oReq.responseType = "text";

    if (params.headers) {
      for (const header in params.headers) {
        if (Object.hasOwnProperty.call(params.headers, header)) {
          oReq.setRequestHeader(header, params.headers[header]);
        }
      }
    }

    if (params.timeout) {
      oReq.timeout = params.timeout;
    }

    if (onProgress) {
      oReq.addEventListener("progress", ({ lengthComputable, loaded, total }) => {
        if (lengthComputable) {
          onProgress(loaded, total);
        }
      });
    }

    oReq.onload = () => {
      // let json: T | undefined = undefined
      latency = Math.round(performance.now() - latency);
      console.info(`request latency: ${latency}`);
      // try {
      //   json = oReq.responseText ? JSON.parse(oReq.responseText) : undefined
      // } catch (error) {
      //   // ignore
      // }
      const body = oReq.response ?? oReq.responseText;

      resolve(
        new Response(body, {
          status: oReq.status,
          statusText: oReq.statusText,
        }),
      );
    };

    oReq.onerror = () => {
      reject(oReq.status || "unknown status");
    };

    latency = performance.now();
    oReq.send(JSON.stringify(params.body));
  });

function replaceUrl(url: string, data: QueryParams): string {
  const regex = new RegExp(`:(${Object.keys(data).join("|")})`, "g");

  // Replace the string by the value in object
  return url.replace(regex, (m, $1) => `${String(data[$1] || m)}`);
}

/**
 * @param search URLSearchParams
 * @returns QueryParams
 */
export const searchParamsToQueryParams = (search: URLSearchParams): QueryParams => {
  const entries = search.entries();
  let params: QueryParams = {};

  for (const [key, value] of entries) {
    params = {
      ...params,
      [snakeToCamelCase(key)]: castType(value),
    };
  }

  return params;
};

export const queryParamsToSearchParams = (params: QueryParams): URLSearchParams => {
  const searchParams = new URLSearchParams();

  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      const value = params[key];

      if (value !== undefined) {
        searchParams.set(camelToSnakeCase(key), String(value));
      }
    }
  }

  return searchParams;
};

/**
 *
 * @param urlString url
 * @param query QueryParams
 * @returns URL with query
 */
const prepareUrl = (urlString: string, query?: QueryParams, urlParams?: QueryParams): URL => {
  let urlStringCopy = urlString;

  if (urlStringCopy.startsWith("/")) {
    urlStringCopy = BASE_API_URL + urlStringCopy;
  }

  const url = new URL(replaceUrl(urlStringCopy, urlParams ?? {}));

  if (query) {
    url.search = queryParamsToSearchParams(query).toString();
  }

  return url;
};

/**
 *
 * @param params FetchParams | undefined
 * @returns FetchParams with string body
 */
const prepareParams = (params?: FetchParams) => ({
  ...params,
  body: params?.body instanceof FormData ? params.body : JSON.stringify(params?.body),
});

/**
 *
 * @param url string
 * @param params FetchParams
 * @returns Abortable
 */
export const request = (
  url: string,
  { onProgress, ...params }: FetchParams = { method: "GET" },
  guarded = true,
): Abortable => {
  let controller: AbortController | undefined = undefined;

  if (!params.signal && Object.hasOwnProperty.call(window, "AbortController")) {
    try {
      controller = new window.AbortController();
      params.signal = controller.signal;
    } catch {
      //
    }
  }

  const execute: Executor = async (overrideParams?: FetchParams): Promise<FetchResponse> => {
    const overrideOnProgress = overrideParams?.onProgress;

    params = {
      ...params,
      ...overrideParams,
      query: {
        ...params.query,
        ...overrideParams?.query,
      },
      body: overrideParams?.body ?? params.body,
      headers: {
        ...params.headers,
        ...overrideParams?.headers,
      },
    };

    if (guarded) {
      const token = getToken();

      if (!token) {
        throw new SessionExpiredException(t`logged.out|Logged out`);
      }

      params = {
        ...params,
        headers: {
          ...params.headers,
          ...overrideParams?.headers,
          Authorization: `Bearer ${token}`,
        },
      };
    }

    const preparedUrl = prepareUrl(url, params.query, params.urlParams);
    const preparedParams = prepareParams(params);
    let response: Response;

    if (onProgress || overrideOnProgress) {
      response = await xhrFetch(
        preparedUrl.toString(),
        overrideParams
          ? {
              method: "GET",
              ...preparedParams,
            }
          : preparedParams,
        onProgress || overrideOnProgress,
      );
    } else {
      response = await fetch(
        preparedUrl.toString(),
        overrideParams
          ? {
              method: "GET",
              ...preparedParams,
            }
          : preparedParams,
      );
    }

    return new FetchResponse(response);
  };

  return {
    controller,
    execute,
  };
};
