import { type Abortable, request } from "api/utils";

import { getProtectedApi } from "api/common";
import type { UserListItemDto } from "model/User";
import { BASE_API_URL } from "util/const";

export const getUserInfo = (): Abortable =>
  request(`${BASE_API_URL}/user/info`, {
    method: "GET",
  });
export const deleteUser = (id: string): Abortable =>
  request(`${BASE_API_URL}/user/${id}/delete`, {
    method: "DELETE",
  });
export const createUser = (user?: UserListItemDto): Abortable =>
  request(`${BASE_API_URL}/user/new`, {
    method: "POST",
    body: {
      user,
    },
  });
export const updateUser = (id: string, user?: UserListItemDto): Abortable =>
  request(`${BASE_API_URL}/user/${id}/update`, {
    method: "PUT",
    body: {
      user,
    },
  });

export const resetByUserId = (userId: string) =>
  getProtectedApi()
    .post<unknown>(`${BASE_API_URL}/user/${userId}/reset`)
    .then(r => r.data);

export const changePasswordRequired = (password: string) =>
  getProtectedApi()
    .post<unknown>(`${BASE_API_URL}/user/change-required-password`, {
      password,
    })
    .then(r => r.data);
