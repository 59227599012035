/* eslint-disable import/no-named-as-default-member */
import { DateTime, Settings } from "luxon";

import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import backend from "i18next-chained-backend";
import httpBackend from "i18next-http-backend";
import localstorageBackend from "i18next-localstorage-backend";
import { initReactI18next } from "react-i18next";
import { PERSISTED_STATE_PREFIX } from "util/const";
import { setLocale } from "yup";
import yupLocale from "yup-locale";

const STORE = window.localStorage;
const PREFIX = `${PERSISTED_STATE_PREFIX}-translations-`;
export const supportedLngs = ["en", "pl"];

export const clearTranslationCache: (lng?: string) => Promise<void> = async lng => {
  for (const key in STORE) {
    if (key.startsWith(PREFIX) && Object.hasOwnProperty.call(STORE, key)) {
      STORE.removeItem(key);
    }
  }

  await i18n.reloadResources();
  await i18n.changeLanguage(lng || i18n.language);
};

i18n.on("languageChanged", lng => {
  Settings.defaultLocale = lng;
});

// let queue: Record<`${string}:${string}`, TranslationItem> = {}
// let timer = 0
// let pending = false
setLocale(yupLocale);

const shortcuts: Record<string, string> = {
  en: "en",
  pl: "pl",
};

let langFromQuery = new URLSearchParams(window.location.search).get("lng") ?? "en";

if (langFromQuery in shortcuts) {
  langFromQuery = shortcuts[langFromQuery];
}

export const i18nextPromise = new Promise<void>(resolve =>
  i18n
    .use(detector)
    .use(backend)
    .use(initReactI18next)
    .init(
      {
        ns: ["default", "error"],
        defaultNS: "default",
        supportedLngs,
        fallbackLng: "pl",
        lng: langFromQuery ?? "en",
        // saveMissing: process.env.NODE_ENV === "development",
        // updateMissing: process.env.NODE_ENV === "development",
        pluralSeparator: "#",
        // missingKeyHandler: (languages: readonly string[], ns: string, key: string, fallbackValue: string) => {
        //   if (process.env.NODE_ENV === "development") {
        //     for (const lang of i18n.languages ?? languages) {
        //       queue[`${lang}:${ns}`] = {
        //         key,
        //         fallbackValue
        //       }
        //     }
        //     if (!pending && !timer) {
        //       timer = window.setTimeout(async () => {
        //         pending = true
        //         try {
        //           const res = await fetch("http://localhost:4000/trans/create", {
        //             method: "POST",
        //             body: JSON.stringify(queue),
        //             headers: {
        //               "Content-Type": "application/json"
        //             }
        //           })
        //           if (res.status === 200) {
        //             queue = {}
        //           }
        //         } catch { } finally {
        //           pending = false
        //           timer = 0
        //         }
        //       }, 2000)
        //     }
        //   }
        // },
        backend: {
          backends: [localstorageBackend, httpBackend],
          backendOptions: [
            {
              prefix: PREFIX,
              expirationTime: 24 * 60 * 60 * 1000,
              defaultVersion: "v1.0.0",
              versions: {},
              store: STORE,
            },
            {
              loadPath:
                import.meta.env.MODE === "development"
                  ? `http://localhost:${window.location.port}/translations/{{lng}}/{{ns}}.json`
                  : `${new URL("./translations", import.meta.url).href.replace(
                      "/assets",
                      "",
                    )}/{{lng}}/{{ns}}.json`, // do not put {} in URL, it will be encoded before replacement
              allowMultiLoading: false,
              // addPath: "http://localhost:4000/trans/create/{{lng}}/{{ns}}",
              // queryStringParams: { v: "1.0.0" }
            },
          ],
        },
        interpolation: {
          skipOnVariables: false,
          format: (value, format) => {
            if (format === "uppercase") return value.toUpperCase();

            if (value instanceof Date) {
              if (format) return DateTime.fromJSDate(value).toFormat(format);

              return DateTime.fromJSDate(value).toLocaleString();
            }

            return value;
          },
        },
        react: {
          useSuspense: true,
        },
      },
      () => resolve(),
    ),
);
