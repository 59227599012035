import axios, { type AxiosInstance } from "axios";
import SessionExpiredException from "exceptions/session_expired";
import { BASE_API_URL } from "util/const";
import { getToken } from "util/token";
export const getApi = (): AxiosInstance =>
  axios.create({
    baseURL: BASE_API_URL,
    headers: {
      "Content-type": "application/json",
    },
  });

export const getProtectedApi = (): AxiosInstance => {
  const instance = axios.create({
    baseURL: BASE_API_URL,
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  });

  instance.interceptors.response.use(
    response => response,
    error => {
      if (error.response?.status === 401) {
        return Promise.reject(new SessionExpiredException("Session expired"));
      }

      return Promise.reject(error);
    },
  );

  return instance;
};
